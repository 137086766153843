<template>
    <div id="message">
        <div class="lists">
            <ul>
                <li v-for="(list, key) in msg_lists">
                    <div>
                        <Badge dot :count="list.isRead==0?1:0">
                            <Avatar v-if="list.avatar" shape="square" size="large" :src="list.avatar"/>
                            <Avatar v-if="!list.avatar" shape="square" size="large"style="color: #fff;background-color:#2d8cf0">{{list.uname}}</Avatar>
                        </Badge>
                    </div>
                    <div class="ml16 msg color50">
                        <router-link :to="list.jumpUrl"><span>{{list.uname}}评论了你：{{list.contents}} 点击查看</span></router-link>
                    </div>
                    <div class="fright fs12 color50">
                        <span>{{list.createdAt}}</span>
                    </div>
                </li>
            </ul>
            <Spin size="large" fix v-if="spinShow" class="spin-container"></Spin>
        </div>
    </div>
</template>

<script>
    import '@/css/common.css';

    export default {
        name: "Message",
        data() {
            return {
                msg_lists: [],
                spinShow: false,
            }
        },
        methods: {
            lists() {
                this.spinShow = true;
                this.axios
                    .get('/api/user/msgLists')
                    .then((response) => {
                        this.spinShow = false;
                        this.msg_lists = response.data.data;
                    })
            },
            setIsRead() {
                this.axios.post('/api/user/setMsgRead');
            }
        },
        mounted() {
            this.lists();
            this.setIsRead();
        }
    }
</script>

<style scoped>
    .lists {
        width: 600px;
        min-height: 500px;
        margin: 0 auto;
        margin-top: 80px;
    }

    .lists li {
        font-size: 14px;
        list-style: none;
        padding: 14px;
        text-align: left;
        border-bottom: 1px solid #ededed;
        overflow: hidden;
    }

    .lists li div {
        float: left;
    }

    .lists .msg {
        width: 400px;
        height: auto;
        overflow: hidden;
        line-height: 1.8;
        color: #505050;
        font-size: 12px;
        white-space: normal;
    }
    .lists .msg a{
        color: #505050;
    }
</style>
